
import { Component, Vue, Watch } from 'vue-property-decorator'
import PermitsLandingPage from './PermitsLandingPage'

@Component({ components: { 'c-permits-landing-page': PermitsLandingPage } })
export default class Permits extends Vue {
  // created // ***
  created() {
    this.redirectIfDisabled()
  }

  // watch // ***
  @Watch('features')
  onFeaturesChanged(): void {
    this.redirectIfDisabled()
  }

  // methods // ***
  onStart() {
    this.$router.push('/permits/view')
  }
  redirectIfDisabled() {
    if (
      this.$store.getters['core/featureDisabled']('permits') ||
      !this.$auth.activeUserIsPermitUser
    )
      this.$router.push('/errors/404')
  }
}
